html {
  font-size:62.5%;
}

@media (min-width: 768px) {
  html {
    font-size: 50%
  }
}

@media (min-width: 1080px) {
  html {
    font-size: 62.5%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #f4f4f4;
}

* {
  outline: none;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: DIN;
  src: url(assets/fonts/DINCondensed-Bold.09d5f29.woff);
  font-weight: bold;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f4f4;
}
