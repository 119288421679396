html {
	box-sizing: border-box;
	background-color: $color-white;
	// This defines what 1rem is
	font-size: 62.5% !important; //1 rem = 10px; 10px/16px = 62.5%
	line-height: 1.4 !important;
}

body {
	font-family: $font-family;
	font-weight: $weight-normal;
}

h1 {
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: normal;
	line-height: 30px;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 33px;
}

h2 {
	text-align: center;
	color: #000000;
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: normal;
	line-height: 16px;
	margin-top: 11px;
	margin-bottom: 21px;
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
}

img {
	display: block;
}

button,
input {
	border: none;
	outline: none;
}

input {
	max-width: 100%;
	height: 50px !important;
	color: #b5b6bd !important;
	font-family: "Open Sans" !important;
	font-size: 16px !important;
	font-weight: 700 !important;
	font-style: normal !important;
	letter-spacing: normal !important;
	line-height: normal !important;
	text-align: left;
	font-style: normal;
	padding: 14px 24px !important;
}

textarea {
	resize: none;
	height: 200px;
	max-width: 100%;
	color: #b5b6bd !important;
	font-family: "Open Sans" !important;
	font-size: 16px !important;
	font-weight: 700 !important;
	font-style: normal !important;
	letter-spacing: normal !important;
	line-height: normal !important;
	text-align: left;
	font-style: normal;
	padding: 14px 24px !important;
}

input[type="file"] {
	display: none;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input.is-invalid:focus {
	border-color: black;
	box-shadow: none !important;
	outline: 0 none;
	border-radius: 0px;
}
