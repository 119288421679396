.steps-wrapper {
	position: relative;
	@media (max-width: 768px) {
		background-color: $color-white;
		padding: 0 8px;
		margin: 0 4px;
		&:first-of-type {
			padding-top: 24px;
		}
		&:last-of-type {
			padding-bottom: 24px;
		}
	}
}
.process-btn {
	margin: 0 auto;
	margin-top: 64px;
	width: 300px;

	@media (max-width: 1024px) {
		width: 343px;
		margin-top: 16px;
		margin-bottom: 24px;
	}
	@media (max-width: 480px) {
		width: 100%;
	}
}
.single-step-row {
	@media (max-width: 768px) {
		display: flex;
		align-items: center;
	}
}
.steps-title {
	color: $color-black-dark !important;
}
.steps-label {
	font-size: 18px !important;
	text-align: center !important;
}
.single-step-3 {
	&::after {
		display: block;
		content: "";
		position: absolute;
		height: 2px;
		background-color: #7d858b7d;
		width: 140px;
		right: -18.2%;
		top: 62.4px;
		@media (max-width: 1024px) {
			display: none;
		}
	}
}
.s1-container {
	padding-top: 90px;
	@media (max-width: 1024px) {
		padding-top: 0;
	}
	h2 {
		margin-top: 20px;
		margin-bottom: 20px;
		line-height: initial;
		width: 80%;
		margin: 0 auto;
		@media (min-width: 768px) {
			margin-top: 60px;
			margin-bottom: 70px;
			width: 100%;
		}

		color: $color-red-dark;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.leftSide {
		img {
			max-width: 100%;
			width: 100%;

			@media (max-width: 768px) {
				padding-right: 18px;
			}
		}
	}

	.rightSide {
		@media (min-width: 992px) {
			padding-left: 115px;
		}
		@media (max-width: 991px) {
			padding: 20px;
		}

		h3 {
			color: #000000;
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 18px;
		}

		p {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			margin-bottom: 0px;
		}

		.fancy {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-left: 70px;
			margin-top: 50px;
			margin-bottom: 50px;
			position: relative;

			&:before {
				content: "";
				height: 120%;
				top: -10%;
				left: 50px;
				width: 1px;
				background-color: $color-red-dark;
				display: block;
				position: absolute;
			}
		}
	}
}

.s2-container {
	h2 {
		margin-top: 70px;
		margin-bottom: 45px;
		@media (min-width: 768px) {
			margin-top: 100px;
			margin-bottom: 70px;
		}
		@media (max-width: 345px) {
			line-height: 40px;
		}

		color: $color-red-dark;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.leftSide {
		padding: 0;
		@media (max-width: 900px) {
			padding-bottom: 24px;
		}
		img {
			max-width: 100%;
			width: 100%;
		}
	}

	.rightSide {
		padding: 0 24px;
		@media (min-width: 900px) {
			padding-right: 96px;
		}

		.bullets-container {
			padding: 0 !important;

			.benefits-bullets {
				margin-top: 30px !important;

				.float-end {
					float: left !important;
				}
			}
		}

		h3 {
			color: #000000;
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 18px;
		}

		p {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			margin-bottom: 0px;
		}

		.fancy {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-left: 70px;
			margin-top: 50px;
			margin-bottom: 50px;
			position: relative;

			&:before {
				content: "";
				height: 120%;
				top: -10%;
				left: 50px;
				width: 1px;
				background-color: $color-red-dark;
				display: block;
				position: absolute;
			}
		}
	}

	.counterRow {
		margin-bottom: 80px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
		background-color: #ffffff;
		@media (max-width: 1139px) {
			background-color: initial;
			box-shadow: none;
		}
		@media (max-width: 768px) {
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
		}

		.whiteCounter {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $color-white;
			position: relative;
			width: 300px;
			padding-top: 36px;
			padding-bottom: 24px;

			@media (max-width: 768px) {
				width: 100%;
			}

			.top {
				color: #c8102e;
				font-family: "Open Sans";
				font-size: 40px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: normal;
				text-align: center;
				z-index: 10;
				@media (max-width: 768px) {
					text-align: center;
				}
			}

			.middle {
				color: #7d858b7d;
				font-family: "Open Sans";
				font-size: 12px;
				font-weight: 700;
				font-style: normal;
				text-align: center;
				z-index: 10;
				@media (max-width: 768px) {
					text-align: center;
				}
			}

			.bottom {
				padding-top: 8px;
				color: #000000;
				font-family: "Open Sans";
				font-size: 18px;
				font-weight: 700;
				font-style: normal;
				letter-spacing: normal;
				text-align: center;
				z-index: 10;
				@media (max-width: 768px) {
					text-align: center;
					margin: auto;
					width: 100%;
					left: 0;
				}
			}

			.icon {
				position: absolute;
				top: 10%;
				left: 10%;
				height: 84px;
				width: 84px;
			}
		}

		.whiteCounter:nth-child(5),
		.whiteCounter:nth-child(4) {
			.top {
				@media (min-width: 1141px) {
					text-align: center;
				}
			}
		}
	}
}

.s3-container {
	padding-bottom: 90px;
	h2 {
		margin-top: 60px;
		margin-bottom: 40px;
		@media (min-width: 768px) {
			margin-top: 120px;
			margin-bottom: 70px;
		}

		color: $color-red-dark;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
	}

	.leftSide {
		img {
			max-width: 100%;
			width: auto;
			max-height: 457px;

			@media (max-width: 768px) {
				height: auto;
				width: 100%;
			}
		}
	}

	.rightSide {
		h3 {
			color: #000000;
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 18px;
		}

		p {
			@media (min-width: 768px) {
				width: 440px;
				position: relative;
				top: 26px;
				left: -50px;
				z-index: 3;
				background-color: #fff;
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
			}

			@media (max-width: 345px) {
				max-width: 100vw;
			}
			vertical-align: middle;
			display: inline-block;
			padding: 62px 72px;

			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: 36px;
			text-align: center;

			.red {
				color: $color-red-dark;
			}
		}

		.fancy {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-left: 70px;
			margin-top: 50px;
			margin-bottom: 50px;
			position: relative;

			&:before {
				content: "";
				height: 120%;
				top: -10%;
				left: 50px;
				width: 1px;
				background-color: $color-red-dark;
				display: block;
				position: absolute;
			}
		}
	}

	@media (max-width: 1274px) and (min-width: 770px) {
		.rightSide {
			p {
				max-width: 100%;
				padding: 20px 40px;
				font-size: 24px;
			}
		}
	}

	@media (max-width: 769px) and (min-width: 767px) {
		.leftSide.col-md-8.col-sm-12 {
			display: none;
		}

		.rightSide.col-md-4.col-sm-12 {
			width: max-content;
			max-width: 100%;
			margin: auto;
			p {
				position: static;
			}
		}
	}
}

.steps-container {
	padding-bottom: 90px;
	@media (max-width: 900px) {
		padding-bottom: 0;
	}

	h2 {
		color: #c8102e;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 36px;
		text-align: center;
		margin-top: 60px;
		@media (max-width: 768px) {
			margin-top: 0;
		}
	}

	h3 {
		color: #000000;
		font-family: "Open Sans";
		font-size: 28px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 32px;
		text-align: left;
		margin-top: 30px;
		margin-bottom: 75px;
		@media (max-width: 768px) {
			margin-bottom: 24px;
		}
	}

	.single-step {
		position: relative;
		padding-bottom: 40px;

		img {
			margin-left: 60px;
			display: block;
			width: 38px;
			margin-bottom: 16px;
			@media (max-width: 768px) {
				margin-bottom: 0;
			}
		}

		.num {
			color: #c8102e;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
		}

		.text {
			/* Style for "Zapoznaję" */
			width: 145px;
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
		}

		&:not(:first-child) {
			@media (min-width: 769px) {
				&:before {
					content: "";
					position: absolute;
					left: -10px;
					width: 40px;
					height: 2px;
					background-color: #7d858b7d;
					width: 40px;
					left: -60px;
					top: 63px;
				}
			}
			@media (max-width: 768px) {
				&::before {
					display: none;
				}
			}
		}

		@media (min-width: 1004px) {
			&.single-step-1 {
				position: relative;
				right: -4%;
			}

			&.single-step-2 {
				position: relative;
				right: -2%;
			}

			&.single-step-4 {
				.text {
					width: 170px;
				}

				img {
					margin-left: 82px;
				}

				&:after {
					content: "";
					position: absolute;
					right: 0px;
					width: 44px;
					height: 162px;
					border: 2.5px solid #7d858b7d;
					border-left: none;
					top: -61.4%;
				}
			}

			&.single-step-5 {
				position: relative;
				left: -30px;

				.text {
					text-align: center;
					width: 230px;
				}

				img {
					margin-left: 90px;
				}
			}

			&.single-step-6 {
				position: relative;
				right: -60px;

				img {
					margin-left: 30px;
				}
			}

			&.single-step-7 {
				position: relative;
				right: -88px;

				&:before {
					content: "";
					position: absolute;
					left: -80px;
					width: 43px;
					height: 162px;
					border: 2.5px solid #7d858b7d;
					border-right: none;
					top: 40.4%;
				}
			}

			&.single-step-8 {
				position: relative;
				left: 20%;
				&::before {
					display: block;
					content: "";
					position: absolute;
					height: 2px;
					background-color: #7d858b7d;
					width: 140px;
					left: -62%;
					top: 63.6px;
				}

				img {
					margin-left: 40px;
				}
			}

			&.single-step-9 {
				position: relative;
				right: -15%;
			}

			&.single-step-10 {
				position: relative;
				left: 12%;
			}
		}

		@media (max-width: 769px) {
			padding-left: 0 8px;
			position: relative;
			padding-bottom: 20px;

			.num {
				width: 26px !important;
			}
			.text {
				background-color: #eee;
				z-index: 10;
			}
			.image {
				width: 40px;

				img {
					margin-left: 0;
					width: 33px;
				}
			}

			&:not(.no-after) {
				&:after {
					z-index: 1;
					content: "";
					position: absolute;
					width: 25vw;
					border: 2px solid #7d858b7d;
					border-left: none;
					left: 50vw;
					display: none;
				}
			}

			&:not(.no-before) {
				&:before {
					content: "";
					position: absolute;
					left: calc(50vw - 170px);
					width: 30px;
					border: 2px solid #7d858b7d;
					border-right: none;
					top: 70px;
					display: none;
				}
			}

			&.single-step-1 {
				&:after {
					top: 20px;
					height: 80px;
				}
				.text {
					background-color: inherit;
					width: 80%;
				}
			}

			&.single-step-2 {
				&:before {
					top: 15px;
					height: 75px;
				}

				.text {
					background-color: inherit;
					width: 80%;
				}
			}

			&.single-step-3 {
				&::after {
					top: 25px;
					height: 90px;
				}

				.text {
					background-color: inherit;
					width: 80%;
				}
			}

			&.single-step-4 {
				&:before {
					top: 15px;
					height: 65px;
				}

				.text {
					background-color: inherit;
					width: 80%;
				}
			}
			&.single-step-5 {
				&:after {
					top: 40px;
					height: 90px;
				}

				.text {
					background-color: inherit;
					width: 80%;
				}
			}

			&.single-step-6 {
				&:before {
					top: 15px;
					height: 65px;
				}

				.text {
					background-color: inherit;
					width: 80%;
				}
			}
			&.single-step-7 {
				&:after {
					top: 25px;
					height: 70px;
				}

				.text {
					background-color: inherit;
					width: 80%;
				}
			}
			&.single-step-8 {
				&:before {
					top: 15px;
					height: 65px;
				}

				.text {
					background-color: inherit;
					width: 80%;
				}
			}
			&.single-step-9 {
				&:after {
					top: 25px;
					height: 70px;
				}

				.text {
					background-color: inherit;
					width: 80%;
				}
			}
			&.single-step-10 {
				.text {
					background-color: inherit;
					width: 80%;
				}
			}
		}

		@media (max-width: 520px) {
			&:not(.no-after) {
				&:after {
					left: 60vw;
				}
			}
		}
		@media (max-width: 430px) {
			&:not(.no-after) {
				&:after {
					left: 69vw;
				}
			}
		}

		@media (max-width: 345px) {
			&:not(.no-before) {
				&:before {
					left: calc(50vw - 145px);
					width: 19px;
				}
			}
		}
	}

	@media (max-width: 769px) and (min-width: 767px) {
		h3 {
			text-align: center;
		}

		.col-md-3 {
			flex: 0 0 auto !important;
			width: 100% !important;
		}

		.offset-md-3 {
			margin-left: 0;
		}
	}
}

.s5-container {
	margin-bottom: 60px;
	@media (max-width: 768px) {
		padding-top: 36px;
		margin-bottom: 32px;
	}

	h2 {
		margin-top: 20px;
		margin-bottom: 20px;
		line-height: initial;
		width: 80%;
		margin: 0 auto;
		margin-top: 60px;
		margin-bottom: 70px;
		color: $color-black-dark;
		font-family: "Open Sans";
		font-size: 32px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		text-align: center;
		@media (max-width: 768px) {
			margin-top: 36px;
			margin-bottom: 24px;
			width: 100%;
		}
	}

	.leftSide {
		img {
			max-width: 100%;
			width: 100%;
		}
	}

	.rightSide {
		padding: 24px;
		@media (min-width: 900px) {
			padding-left: 96px;
		}

		h3 {
			color: #000000;
			font-family: "Open Sans";
			font-size: 28px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			margin-bottom: 22px;
		}

		h4 {
			color: #000000;
			font-size: 16px;
			font-weight: 700;
		}

		p {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			margin-bottom: 0px;
		}

		.fancy {
			color: #000000;
			font-family: "Open Sans";
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: normal;
			text-align: left;
			padding-left: 70px;
			margin-top: 50px;
			margin-bottom: 50px;
			position: relative;

			&:before {
				content: "";
				height: 120%;
				top: -10%;
				left: 50px;
				width: 1px;
				background-color: $color-red-dark;
				display: block;
				position: absolute;
			}
		}
	}
}

.fixed-button {
	position: fixed;
	bottom: 30px;
	top: initial;

	z-index: 100;
	left: 2vw;
	@media (min-width: 769px) {
		left: calc(50% - 150px);
	}

	@media (max-width: 345px) {
		width: 80vw;
		left: 10vw;
	}
}

@media (max-width: 1200px) {
	.special-padding {
		.text {
			padding-right: 20px;
		}
	}
}

@media (max-width: 769px) and (min-width: 767px) {
	.order-4 {
		order: 4 !important;
	}
	.order-3 {
		order: 3 !important;
	}
	.order-2 {
		order: 2 !important;
	}
	.order-1 {
		order: 1 !important;
	}

	.steps-container .single-step:not(:first-child):before {
		background: none !important;
	}
}

@media (max-width: 1160px) and (min-width: 1004px) {
	#steps-container {
		padding-right: 40px !important;
	}

	.steps-container .single-step:not(:first-child):before {
		width: 28px;
		left: -40px;
	}

	.steps-container h3 {
		text-align: center;
	}

	.single-step.step-10 {
		.num {
			min-width: max-content;
		}
	}
}

@media (max-width: 1004px) and (min-width: 770px) {
	#steps-container {
		padding-left: 40px !important;
	}

	.steps-container .single-step:not(:first-child):before {
		width: 28px;
		left: -40px;
	}

	.single-step.step-10 {
		.num {
			min-width: max-content;
		}
	}
}

body.en {
	.whiteCounter:nth-child(1) {
		.top {
			line-height: 60px;
		}
	}
}

@media (max-width: 1100px) and (min-width: 1000px) {
	body.en {
		.steps-container {
			.single-step.step-6 {
				padding-left: 0;
				margin: 0 15px 0 -15px;
			}
		}
	}
}

@media (max-width: 1005px) and (min-width: 1004px) {
	.steps-container .single-step.single-step-7 {
		position: relative;
		right: -90px;
	}
}

@media screen and (max-width: 1100px) {
	.steps-container .single-step.offset-md-2 {
		margin: 0;
	}
	.steps-container .single-step:after,
	.steps-container .single-step:before {
		display: none !important;
	}
}